export const canadaFreight = new Map([
  ["CA-QC", { fullName: "Quebec", value: 150 }],
  ["CA-ON", { fullName: "Ontario", value: 150 }],
  ["CA-ON-Toronto", { fullName: "Toronto", value: 75 }],
  ["CA-NS", { fullName: "Nova Scotia", value: 300 }],
  ["CA-NB", { fullName: "New Brunswick", value: 300 }],
  ["CA-NL", { fullName: "Newfoundland and Labrador", value: 500 }],
  ["CA-MB", { fullName: "Manitoba", value: 300 }],
  ["CA-SK", { fullName: "Saskatchewan", value: 300 }],
]);

export const usaFreight = new Map([
  ["US-AL", { fullName: "Alabama", value: 150 }],
  ["US-AZ", { fullName: "Arizona", value: 150 }],
  ["US-AR", { fullName: "Arkansas", value: 200 }],
  ["US-CA", { fullName: "California", value: 150 }],
  ["US-CO", { fullName: "Colorado", value: 200 }],
  ["US-CT", { fullName: "Connecticut", value: 150 }],
  ["US-DE", { fullName: "Delaware", value: 150 }],
  ["US-FL", { fullName: "Florida", value: 150 }],
  ["US-GA", { fullName: "Georgia", value: 150 }],
  ["US-ID", { fullName: "Idaho", value: 200 }],
  ["US-IL", { fullName: "Illinois", value: 150 }],
  ["US-IN", { fullName: "Indiana", value: 150 }],
  ["US-IA", { fullName: "Iowa", value: 150 }],
  ["US-KS", { fullName: "Kansas", value: 200 }],
  ["US-KY", { fullName: "Kentucky", value: 150 }],
  ["US-LA", { fullName: "Louisisana", value: 150 }],
  ["US-ME", { fullName: "Maine", value: 200 }],
  ["US-MD", { fullName: "Maryland", value: 150 }],
  ["US-MA", { fullName: "Massachusetts", value: 150 }],
  ["US-MI", { fullName: "Michigan", value: 150 }],
  ["US-MN", { fullName: "Minnesota", value: 200 }],
  ["US-MS", { fullName: "Mississippi", value: 150 }],
  ["US-MO", { fullName: "Missouri", value: 150 }],
  ["US-MT", { fullName: "Montana", value: 200 }],
  ["US-NE", { fullName: "Nebraska", value: 200 }],
  ["US-NV", { fullName: "Nevada", value: 150 }],
  ["US-NH", { fullName: "New Hampshire", value: 200 }],
  ["US-NJ", { fullName: "New Jersey", value: 150 }],
  ["US-NM", { fullName: "New Mexico", value: 200 }],
  ["US-NY", { fullName: "New York", value: 150 }],
  ["US-NC", { fullName: "North Carolina", value: 150 }],
  ["US-ND", { fullName: "North Dakota", value: 200 }],
  ["US-OH", { fullName: "Ohio", value: 150 }],
  ["US-OK", { fullName: "Oklahoma", value: 200 }],
  ["US-OR", { fullName: "Oregon", value: 200 }],
  ["US-PA", { fullName: "Pennsylvania", value: 150 }],
  ["US-RI", { fullName: "Rhode Island", value: 150 }],
  ["US-SC", { fullName: "South Carolina", value: 150 }],
  ["US-SD", { fullName: "South Dakota", value: 200 }],
  ["US-TN", { fullName: "Tennessee", value: 150 }],
  ["US-TX", { fullName: "Texas", value: 150 }],
  ["US-UT", { fullName: "Utah", value: 200 }],
  ["US-VT", { fullName: "Vermont", value: 200 }],
  ["US-VA", { fullName: "Virginia", value: 150 }],
  ["US-WA", { fullName: "Washington", value: 200 }],
  ["US-DC", { fullName: "Washington DC", value: 150 }],
  ["US-WV", { fullName: "West Virginia", value: 150 }],
  ["US-WI", { fullName: "Wisconsin", value: 200 }],
  ["US-WY", { fullName: "Wyoming", value: 200 }],
]);

export const freightByRegion = new Map([...canadaFreight, ...usaFreight]);