import {
  For,
  Show,
  Resource,
  createResource,
  Suspense,
  ErrorBoundary,
} from "solid-js";
import { useSearchParams, RouteSectionProps } from "@solidjs/router";
import { Title } from "@solidjs/meta";
import Body from "~/components/Body";
import MapGL, { Marker, Control } from "solid-map-gl";
import { SelectBox } from "~/components/inputs";
import shipping from "~/assets/icons/supportIcons/shipping.svg";
import { mapPin } from "solid-heroicons/solid";
import { plus } from "solid-heroicons/solid";
import { check } from "solid-heroicons/solid";
import { xMark } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import { createMemo } from "solid-js";
import { flags } from "~/utils/icons";
import { A } from "@solidjs/router";
import "mapbox-gl/dist/mapbox-gl.css";
import { Img } from "~/components/image";
import { BaseSkeleton, MetaDetails } from "~/components/utility";
import {
  getBuilderContentByModel,
  getBuilderContentById,
} from "~/utils/builder";
import { BuilderContent } from "@builder.io/sdk-solid";
import { freightByRegion } from "~/utils/freight";
import { GenericError } from "~/components/utility/errors";

export type FreightModel = {
  data: {
    regionName: string;
    slug: string;
    countryIsoCode: string;
    // date - string or date object?
    effectiveDate: string;
    moulding: {
      title: string;
      titleDetail: string;
      regionRate: [
        {
          region: string;
          rate: number;
          condition: "More" | "Less";
          noRate?: boolean;
        }
      ];
    };
    photoFrame: {
      title: string;
      titleDetail: string;
      regionRate: [
        {
          region: string;
          rate: number;
          condition: "More" | "Less";
        }
      ];
    };
    freightConcessionCopy: string;
    exclusionsCopy: string;
    excludedRegion: [{ regionName: string }];
    disclaimerCopy: string;
  };
  otherRegions: [
    { data: { regionName: string; slug: string; countryIsoCode: string } }
  ];
};

export default function FreightPage(props: RouteSectionProps) {
  const [content] = createResource(
    () => props.params.slug,
    async (id) => {
      const data = await getBuilderContentById(id, "freight");
      const regions = await getBuilderContentByModel("freight", 10, 0);

      (data as BuilderContent & { otherRegions: any }).otherRegions = regions;
      return data as BuilderContent & { otherRegions: BuilderContent };
    }
  ) as any; // Whooopsie

  const [params, setParams] = useSearchParams<{ postal: string }>();
  const [marker] = createResource(
    () => params.postal,
    async (code) => {
      if (code.length < 3) {
        return null;
      }
      const params = new URLSearchParams();
      params.set("access_token", import.meta.env.VITE_MAPBOX_ACCESS_TOKEN);
      params.set(
        "postcode",
        code.length === 4 ? `0${code}` : code
      ); /* prepends a 0 to 4-digit zip codes, results were more consistent */
      params.set("proximity", "ip");
      const response = await fetch(
        `https://api.mapbox.com/search/geocode/v6/forward?${params}`
      );
      if (!response.ok) {
        return null;
      }
      const result = await response.json();
      if (result.features.length == 0) {
        return null;
      }
      // filter out countries that aren't CA/US, return first result after filtering
      const feature = result.features.filter(
        (feature: {
          properties: { context?: { country?: { country_code?: string } } };
        }) =>
          ["CA", "US"].includes(
            feature.properties.context?.country?.country_code ?? "NA"
          )
      )[0];

      if (!feature) return null;

      const coords = feature.geometry.coordinates;
      const city = feature.properties.context.place?.name;
      let regionCodeFull = feature.properties.context.region?.region_code_full;

      // special case - all other rates are by prov/state
      if (city === "Toronto" && regionCodeFull) regionCodeFull += "-Toronto";

      let freight: number | undefined;
      if (regionCodeFull) freight = freightByRegion.get(regionCodeFull)?.value;

      return {
        freight,
        coordinates: coords,
      };
    }
  );
  const countries = createMemo(() => {
    if (!content()) return [];
    return content()?.otherRegions?.map(
      (item: FreightModel["otherRegions"][number]) => {
        return {
          label: () => (
            <A
              href={`/support/freight-program/${item.data.slug}`}
              class="flex items-center space-x-3"
            >
              <Img
                src={flags[item.data.countryIsoCode]}
                class="w-5 h-5"
                width={20}
                height={20}
                alt=""
              />
              <p>{item.data.regionName}</p>
            </A>
          ),
          value: item.data.slug,
        };
      }
    );
  });

  const formattedEffectiveDate = () => {
    if (!content()?.data.effectiveDate) return "";

    const date = new Date(content()?.data.effectiveDate);
    const formatted = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return `Effective ${formatted}`;
  };

  return (
    <>
      <ErrorBoundary
        fallback={(error, reset) => {
          console.log("ERROR", error);
          return (
            <>
              <GenericError error={error} reset={reset} />
            </>
          );
        }}
      >
        <Suspense fallback={<BaseSkeleton height={400} />}>
          <Show when={content()}>
            <Title>Romamoulding.com - Freight Program</Title>
            <MetaDetails
              title="Freight Program"
              description="Learn about our freight program, if you qualify, terms and conditions."
              keywords={"Freight Program"}
            />
            <Body>
              <div />
              <main class="flex flex-col max-w-2xl mx-auto gap-10 py-20 px-3">
                <h2 class="text-4xl font-bold">Freight Program</h2>
                <section class="">
                  <div class="border p-8 grid md:grid-cols-2 items-end ">
                    <div>
                      <div class="w-10 aspect-square pb-5">
                        <Img
                          src={shipping}
                          alt=""
                          width={40}
                          height={30}
                          class="w-full"
                        />
                      </div>
                      <p class="text-sm font-bold">
                        Am I eligible for freight concession?
                      </p>
                    </div>
                    <div>
                      <form
                        onSubmit={(evt) => {
                          evt.preventDefault();
                          const postal = document.getElementById(
                            "postal"
                          ) as HTMLInputElement;
                          if (postal != null && postal.value != "") {
                            setParams({ postal: postal.value });
                          }
                        }}
                        class="flex gap-2 mt-4 -mx-4 sm:mx-0"
                      >
                        <div class="w-full rounded-full relative flex">
                          <input
                            id="postal"
                            name="postal"
                            type="text"
                            required
                            value={params.postal || ""}
                            placeholder="Enter Zip/Postal Code"
                            class=" pl-8 py-2 border rounded-full form-input placeholder:text-xs text-xs w-full focus-roma"
                          />
                          <Icon
                            class="w-5 h-5 text-gray-300 absolute top-1/2 transform -translate-y-1/2 left-2"
                            path={mapPin}
                          />
                        </div>
                        <button
                          type="submit"
                          class="px-3 py-2 text-sm text-white bg-black hover:bg-transparent hover:text-black border-black border transition-colors rounded-full focus-roma"
                        >
                          Go
                        </button>
                      </form>
                    </div>
                    <Show when={marker.latest}>
                      <MapGL
                        class="h-[40vh] col-span-2 w-full mt-10 rounded-md border-roma-gray border"
                        viewport={{
                          center: marker?.latest?.coordinates,
                          zoom: 11,
                        }}
                        options={{
                          projection: "mercator",
                          dragPan: true,
                          doubleClickZoom: true,
                          scrollZoom: false,
                          interactive: true,
                          accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
                          style: "mb:light",
                        }}
                      >
                        <Control type="navigation" position="top-left" />
                        <Marker
                          lngLat={marker?.latest?.coordinates}
                          showPopup={true}
                          options={{
                            color: "#0079d1",
                            focusAfterOpen: true,
                            open: true,
                          }}
                        />
                      </MapGL>
                    </Show>
                  </div>
                  <Show
                    when={marker.latest}
                    fallback={
                      <Show when={marker.latest === null}>
                        <div class="bg-roma-grey rounded-lg py-3 px-4 mt-2 border text-sm text-roma-dark-grey ">
                          We could not find a matching location, please try
                          another postal/zip code, or check the table below to
                          find your province/state.
                        </div>
                      </Show>
                    }
                  >
                    <div class="flex justify-center md:col-span-2 border border-t-0 p-8">
                      <Show
                        fallback={
                          <div class="flex">
                            <div class="border-red-500 border rounded-full w-7 h-7 flex justify-center items-center">
                              <Icon path={xMark} class="w-7 text-red-500" />
                            </div>
                            <p class="text-sm ml-2">
                              The Freight Program <b>is not available</b> in
                              your region yet. Please check back soon!
                            </p>
                          </div>
                        }
                        when={marker.latest?.freight}
                      >
                        <div class="flex items-center">
                          <div class="border-green-500 border rounded-full w-7 h-7 flex justify-center items-center">
                            <Icon path={check} class="w-7 text-green-500" />
                          </div>
                          <p class="text-sm ml-4">
                            <b>Congratulations</b>, you are eligible for the
                            Freight Program on Simply Roma or Roma One puchases
                            greater than ${marker?.latest?.freight}!
                          </p>
                        </div>
                      </Show>
                    </div>
                  </Show>
                  <p class="text-xs text-gray-700 pt-4">
                    Offer not applicable to select partners in remote areas as
                    determined by Roma Moulding Inc., please enter your postal /
                    zip code for applicable areas.
                  </p>
                </section>
                <section class="flex flex-col gap-10">
                  <div class="flex justify-between">
                    <h3 class="text-3xl font-bold">Terms &amp; Conditions</h3>
                    <SelectBox
                      placeholder="Select Country"
                      class="px-5"
                      options={countries()}
                      triggerClass="!rounded-full "
                      defaultValue={props.params.slug}
                    />
                  </div>
                  <div class="space-y-2">
                    <h4 class="text-lg font-bold">Minimum Order</h4>
                    <p class="text-sm">{formattedEffectiveDate()}</p>
                  </div>
                  <ul class="text-sm ">
                    <h5 class="font-bold pb-3 border-b">
                      {content()?.data.moulding.title}
                      <Show when={content()?.data.moulding.titleDetail}>
                        <span class="font-normal text-xs ml-2">
                          {content()?.data.moulding.titleDetail}
                        </span>
                      </Show>
                    </h5>
                    <For each={content()?.data.moulding.regionRate}>
                      {(item) => (
                        <li class="grid grid-cols-5 items-center py-3 border-b">
                          <p class="col-span-4">{item.region}</p>
                          <Show
                            when={item.noRate}
                            fallback={
                              <p>
                                ${item.rate}
                                {item.condition === "More"
                                  ? " or more"
                                  : " or less"}
                              </p>
                            }
                          >
                            <p>N/A</p>
                          </Show>
                        </li>
                      )}
                    </For>
                  </ul>
                  <ul class="text-sm ">
                    <h5 class="font-bold pb-3 border-b">
                      {content()?.data.photoFrame.title}
                      <Show when={content()?.data.moulding.titleDetail}>
                        <span class="font-normal text-xs ml-2">
                          {content()?.data.photoFrame.titleDetail}
                        </span>
                      </Show>
                    </h5>
                    <For each={content()?.data.photoFrame.regionRate}>
                      {(item) => (
                        <li class="grid grid-cols-5 items-center py-3 border-b">
                          <p class="col-span-4">{item.region}</p>
                          {/* <Switch>
                            <Match when={item.conditon === "More"}>
                              <p>${item.rate} or more</p>
                            </Match>
                            <Match when={item.conditon === "Less"}>
                              <p>${item.rate} or less</p>
                            </Match>
                          </Switch> */}
                          <p>
                            ${item.rate}
                            {item.condition === "More"
                              ? " or more"
                              : " or less"}
                          </p>
                        </li>
                      )}
                    </For>
                  </ul>
                  <div
                    class="richtext text-sm"
                    innerHTML={content()?.data.freightConcessionCopy}
                  />
                  <div
                    class="richtext text-sm"
                    innerHTML={content()?.data.exclusionsCopy}
                  />
                  <div class="text-sm">
                    <input
                      type="checkbox"
                      class="peer accordion-input sr-only"
                      id="excludedRegions"
                    />
                    <label
                      for="excludedRegions"
                      class="font-bold accordion-label flex items-start cursor-pointer "
                    >
                      <p class="leading-0">Show Excluded Regions</p>
                      <Icon
                        path={plus}
                        class="ml-2 w-4 accordion-icon-45 transition duration-200"
                      />
                    </label>
                    <ul class="max-h-0 overflow-hidden peer-checked:max-h-96 transition-all duration-700  text-gray-500">
                      <For each={content()?.data.excludedRegion}>
                        {(item) => <li>{item.regionName}</li>}
                      </For>
                    </ul>
                  </div>
                  <div
                    class="text-xs text-gray-500"
                    innerHTML={content()?.data.disclaimerCopy}
                  />
                </section>
              </main>
            </Body>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
